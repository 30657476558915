import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";

const NavigationMainMenu = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  const [classCroixMenuBurger, setClassCroixMenuBurger] = useState("");



    return (
        <div className='navigation-main-menu'>
            <button type="button" className={`nav-toggler ${classCroixMenuBurger}`} aria-label="toggle nav menu" onClick={()=>((classCroixMenuBurger === 'active')?setClassCroixMenuBurger("") : setClassCroixMenuBurger("active"))}>
                <span className="line-hamburger line-hamburger-top"></span>
                <span className="line-hamburger line-hamburger-middle"></span>
                <span className="line-hamburger line-hamburger-bottom"></span>
            </button>
            <ul className={`liste-items-menu ${classCroixMenuBurger}`}>
                <NavLink to="/home-trimming" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                    <li>Home trimming</li>
                </NavLink>
                <NavLink to="/services" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                    <li>Services</li>
                </NavLink>
                <NavLink to="/about" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                    <li>About us</li>
                </NavLink>
                <NavLink to="/contact" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                    <li>Contact</li>
                </NavLink>
            </ul>

        </div>
    );
};

export default NavigationMainMenu;