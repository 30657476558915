export default function Fruit({fruitInfo,onClick}){
    //state

    //comportement

    //affichage (render)
    return (
    <li>
        {fruitInfo.nom}
        <button onClick={() => onClick(fruitInfo.id)}>X</button>
    </li>
    );
}