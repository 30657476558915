import React from 'react';

import '../styles/pages/_home-trimming.scss';
import '../styles/components/_navigation-main-menu.scss';
import '../styles/components/_footer.scss'
import '../styles/components/_card-telephone.scss'
import '../styles/components/_logo.scss'

import imageBannerSrcJpg from '../assets/images/Palm-trees-with-foliage-growing-up-the-trunks-against-a-blue-sky-with-cloud-Oahu-Hawaii-United-States.jpg';
import imageBannerSrcWebp from '../assets/images/Palm-trees-with-foliage-growing-up-the-trunks-against-a-blue-sky-with-cloud-Oahu-Hawaii-United-States.webp';

import NavigationMainMenu from '../components/NavigationMainMenu'
import Footer from '../components/Footer'
import CardTelephone from '../components/CardTelephone'
import Logo from '../components/Logo'

const HomeTrimming = () => {
    return (
        <div className='home-trimming'>
            <NavigationMainMenu/>

            <Logo/>
            <div className='banner-home-trimming'>
                <h1>Oahu Tree Trimming and Removal Experts</h1>
                <p>Oahu Tree Trimming & Oahu Tree Removal Experts are here to keep your home looking healthy and vibrant. Offering the Best Oahu Tree Service on the island!
                </p>
                <picture>
                    <source type='image/webp' srcSet={imageBannerSrcWebp} />
                    <img src={imageBannerSrcJpg} alt="Hawaii landscape with trees on wind" />
                </picture>
            </div>

            <h2>Nom de notre compagnie !! Services</h2>
            <p>When you choose the best Oahu Tree Service crew around you can only expect the best work in return. We are here to make sure you are taken care of. We specifically specialize in tree trimming and tree removal, so rest assured you are going with the best.
            </p>

            <h2>Our sevices</h2>
            <ul className='liste'>
                <li>Tree Trimming</li>
                <li>Tree Removal</li>
                <li>Landscaping Services</li>
            </ul>

            <CardTelephone/>

            <h2>A Little About Our Process</h2>
            <p>Oahu Tree Trimming and Removal Experts have serviced many clients over the past few years in Honolulu and Oahu. Our Oahu tree service team would love to help you as well. Before coming to you, you will get in touch with one of our employees and give us a brief explanation of what you are looking for. We will give you an estimated price and may send someone out to access the job before we give a quote. When everything is agreed upon we will set a date and time at your earliest convenience and get your job done in a timely manner. We will clean up after we are done and educate you on what is next for your tree. We are dedicated to give our customers the finest quality work and offer the most competitive prices around. We stay up to date on current environmental laws and regulations and new or emerging issues with trees in our area. We are dedicated to you and your property, which is why we are highly rated and respected in the community. With every job, we go in with the intention to preserve and protect trees and professionally deliver service with a smile! Contact us for a quote today! Visit our Landscaping Oahu friends for all of your yard needs that go beyond tree care!
            </p>

            <h2>Innovation and Excellence</h2>
            <p>Oahu tree trimming and Oahu tree removal are no easy tasks for someone out of practice. Our arborists are trained professionals that can handle any of your tree care needs with precision and care. We handle each project with a high level of importance and are sure to deliver a perfect product to you each and every time. These are just a few of the things that set us apart from the rest when it comes to our Oahu tree service. Give us a call to find out more about what we can do for you.</p>

            <h2>Areas We Serve</h2>
            <ul className='liste'>
                <li>Waimea</li>
                <li>Ewa Beach</li>
                <li>Honolulu</li>
                <li>Aiea</li>
                <li>Mililani</li>
                <li>Kailua</li>
                <li>Waimānalo</li>
                <li>Oahu</li>
                <li>Hawaii Kai</li>
            </ul>


            <Footer/>

        </div>
    );
};

export default HomeTrimming;