import React from 'react';

import '../styles/pages/_contact.scss';
import '../styles/components/_navigation-main-menu.scss';
import '../styles/components/_footer.scss'

import imageBannerSrcJpg from '../assets/images/coconut-tree-gardens-in-oahu-2880w.jpg';
import imageBannerSrcWebp from '../assets/images/coconut-tree-gardens-in-oahu-2880w.webp';

import NavigationMainMenu from '../components/NavigationMainMenu'
import Footer from '../components/Footer'
import Logo from '../components/Logo'

const Contact = () => {
    return (
        <div className='contact'>
            <NavigationMainMenu/>
            <Logo/>

            <div className='banner-contact'>
                <span>Contact</span>
                <picture>
                    <source type='image/webp' srcSet={imageBannerSrcWebp} />
                    <img src={imageBannerSrcJpg} alt="Hawaii landscape with trees on wind" />
                </picture>
            </div>

            <h1>Get in Touch</h1>
            <p>We are a dedicated team of arborists who go above and beyond for our clients in Honolulu and Oahu. We believe that every tree has a life of their own so we treat them as so! We specialize in tree removal and trimming on personal property as well as business and commercial properties. We have been servicing the Honolulu and Oahu area for many years and strive to continue to have a great relationship with our community. We are a group of hard working, caring individuals that take pride in what we do. We know some clients have a special connection to their trees, so we make sure to treat every tree like it is our own! Nowhere else in Hawaii will you find people who care more about trees than us. So please feel free to contact us and let us know how we can best be of service to you! Feel free to call us.
            </p>


            <Footer/>

        </div>
    );
};

export default Contact;