import React from 'react';
import Navigation from '../components/Navigation'
import Countries from '../components/Countries'
import Footer from '../components/Footer'

const Api = () => {
    return (
        <div>
            <Navigation/>
            <h1>API countries</h1>
            <Countries/>
            <Footer/>
        </div>
    );
};

export default Api;