import React from 'react';

import '../styles/pages/_services.scss'
import '../styles/components/_navigation-main-menu.scss';
import '../styles/components/_footer.scss'

import imageBannerSrcJpg from '../assets/images/Hawaii-Landscaping-Services-scaled.jpg';
import imageBannerSrcWebp from '../assets/images/Hawaii-Landscaping-Services-scaled.webp';

import NavigationMainMenu from '../components/NavigationMainMenu'
import Footer from '../components/Footer'
import Logo from '../components/Logo'

const Services = () => {
    return (
        <div className='services'>
            <NavigationMainMenu/>

            <Logo/>
            <div className='banner-services'>
                <span>Services</span>
                <picture>
                    <source type='image/webp' srcSet={imageBannerSrcWebp} />
                    <img src={imageBannerSrcJpg} alt="Hawaii landscape with trees on wind" />
                </picture>
            </div>

            <h1>Nom de notre compagnie !! Services</h1>
            <p>When you choose the best Oahu Tree Service crew around you can only expect the best work in return. We are here to make sure you are taken care of. We specifically specialize in tree trimming and tree removal, so rest assured you are going with the best.
            </p>

            <h2>Tree Trimming</h2>
            <p>If you aren't planning on getting rid of your trees completely then you have to be careful to take good care of them. It is easy to hurt or even kill a tree when trimming them if you don't know what you're doing. Trees are living things and must be groomed the correct way. We have a team of highly qualified professional arborists that have years of experience trimming trees. We service all trees in Oahu and you can even see some of the main trees in the link. We have all the equipment necessary to be able to trim palm trees that are hundreds of feet in the air and we have the precision to trim trees close to electrical wiring. We care about keeping your tree in good health and can guarantee that your tree will continue to grow healthy even after we are done treating it. For a professional opinion or expert services look no further. Our Oahu tree trimming experts will talk with you and ensure that we understand exactly what you want before we complete your project exactly as discussed!
            </p>
            <p>Our Oahu Tree Trimming Experts are highly skilled when it comes to tree trimming and we can ensure that you will be satisfied with our services. Give us a call today for a free quote on your new job. We guarantee the best Oahu tree service around!</p>

            <h2>Tree Removal</h2>
            <p>Removing a tree or a stump can be a real pain, that's where our Oahu tree removal experts come in. Especially when it comes to larger trees and trunks that are rooted far into the ground. We here at Oahu Tree Services have a multitude of different strategies that we use to remove trees or stumps of any size. No one likes having a tree stump just sitting in their backyard and some trees can actually post a hazard to the overall safety of your residency. You have to be careful who you hire in Oahu to do any tree or stump removals. Some people are unqualified to do this job and can end up damaging your yard or even worse your home. We have a team of highly experienced professionals that can handle any job no matter how big or small. Don't hesitate to call our experts Oahu tree removal team. We have reasonable prices for our tree and stump removals and can make quick work of any job so you can start to appreciate your property just a little bit more. For any more questions feel out our contact form or feel free to give our Oahu tree removal experts a call!
            </p>
            <p>If you have a dead tree or stump it can be a good idea to remove it so all your surrounding trees and plants can flourish. We are always willing to give free quotes for any of your jobs so feel free to fill out our quote form or give the best Oahu tree trimming and Oahu tree removal crew a call!
            </p>

            <h2>Landscaping Services</h2>
            <p>Our services don't just stop at Oahu tree trimming and Oahu tree removal. Our contractors have a much wider skill set that covers everything landscaping. We can install a waterfall for your backyard, trim your hedges, build a new retaining wall, and anything else you might want. Landscaping can be a big job so it is best to leave it to the professionals! We have over twenty years of experience building some of the most beautiful backyard scenes in Hawaii. Not only do we offer general landscaping services, but we also offer landscaping design for those who don't already have an exact idea of what it is they want to be done! We are best known for our tree removal and trimming services, but if you are in need of a landscaper feel free to give us a call or fill out our quote form. Oahu Tree Trimming and Removal Experts will be sure to give you free estimates and any advice you may need!
            </p>

            <Footer/>

        </div>
    );
};

export default Services;