import React from 'react';

const CardCountry = ({country}) => {
    return (
        <li className='card-country'>
            <img
                width="200px"
                src={country.flags.svg}
                alt={"drapeau " + country.translations.fra.common}
            />
            <div className='infos-country'>
                <h2>{country.translations.fra.common}</h2>
                <h3>{country.capital}</h3>
                <p>pop: {country.population.toLocaleString()}</p>
            </div>
        </li>
    );
};

export default CardCountry;