// import axios from 'axios'
import React, { useEffect, useState } from 'react';
import CardCountry from './CardCountry'

const Countries = () => {

    const [data, setData] = useState([]);
    const [rangeValue, setRangeValue] = useState(6);
    const continents = ["Africa", "Americas", "Asia", "Europe", "Oceania"];
    const [selectedRadio, setSelectedRadio] = useState("");

    // useEffect se s'effectue lorsque le composant est monté ; le deuxième paramètre [] est un callBack (non utilisé ici)
    useEffect(() => {
        // axios.get("https://restcountries.com/v3.1/all")
        // .then((res) => {
        //     console.log("axios");
        //     setData(res.data);
        // });

        fetch("https://restcountries.com/v3.1/all")
        .then(response => response.json())
        .then(data => {
            console.log("fetch");
            setData(data);
        })
        .catch(error => console.error(error));

    },[]);


    return (
        <div className='countries'>
            <ul className="radio-container">
                <input type="range" min="1" max="250" onChange={(event) => setRangeValue(event.target.value)} defaultValue={rangeValue}/>
                {continents.map((continent) => (
                    <li key={continent}>
                        {/* Le name identique à chaque input radio pour qu'un seul input radio soit selectionnable */}
                        <input
                            type='radio'
                            id={continent}
                            name='continentRadio'
                            onChange={(event) => (setSelectedRadio(event.target.id))}
                            checked={continent === selectedRadio}
                        />
                        <label htmlFor={continent}>{continent}</label>
                    </li>
                ))}
            </ul>
            {selectedRadio && <button onClick={()=>setSelectedRadio("")}>Annuler le tri par continent</button>}
            <ul>
                {
                    data.filter((country)=>(country.region.includes(selectedRadio)))
                    .sort((a,b) => b.population - a.population)
                    .slice(0,rangeValue)
                    .map((country, index) =>
                        // <li key={index}>{country.translations.fra.common}</li>
                        <CardCountry key={index} country={country}/>
                    )
                }
            </ul>
        </div>
    );
};

export default Countries;