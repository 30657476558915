import React from 'react';
import { NavLink } from "react-router-dom";

const Navigation = () => {
    return (
        <div className='navigation'>
            <ul>
                <NavLink to="/" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                    <li>Accueil</li>
                </NavLink>
                <NavLink to="/formulaire" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                    <li>Formulaire</li>
                </NavLink>
                <NavLink to="/api" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                    <li>API</li>
                </NavLink>
                <NavLink to="/test" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                    <li>Test</li>
                </NavLink>
                <NavLink to="/font" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                    <li>Font</li>
                </NavLink>
                <NavLink to="/home-trimming" className={(nav) => (nav.isActive ? "nav-active" : "")}>
                    <li>Home trimming</li>
                </NavLink>
            </ul>

        </div>
    );
};

export default Navigation;