import React from 'react';
import { useState } from "react";
import Fruit from "../components/Fruit";
import FruitForm from "../components/FruitForm";
import Navigation from "../components/Navigation";

const Formulaire = () => {
  // state (état, données)
  const [compteur, setCompteur] = useState(1);

  // comportements
  const handleIncrement = () => {
    setCompteur(compteur + 1);
  };

  //const inputRef = useRef();


  // state (état, données)
  const [fruits, setFruits] = useState([
    { id: 1, nom: "Abricot" },
    { id: 2, nom: "Banane" },
    { id: 3, nom: "Cerise" }
  ]);



  // comportements
  const handleDelete = (id) => {
    console.log(id);
    //1. Copie du state
    const fruitsCopy = [...fruits];
    // const fruitsCopy = fruits.slice(); Est une possibilité

    //2. Manipuler le state
    const fruitsCopyUpdated = fruitsCopy.filter(fruit => fruit.id !== id);

    //3. Modifier mon state avec le setter
    setFruits(fruitsCopyUpdated);
  };

  const handleAddFruit = (fruitToAdd) => {
    const fruitsCopy = [...fruits];
    fruitsCopy.push(fruitToAdd);
    setFruits(fruitsCopy);

  }

  // affichage (render)
  return (
      <div>
        <Navigation/>
        <h1>{compteur}</h1>
        <button onClick={handleIncrement}>Incrémente</button>

        <hr></hr>

        <h1>Liste de fruits</h1>
        <ul>
          {fruits.map((fruit) => (
            <Fruit
              key={fruit.id}
              fruitInfo={fruit}
              onClick={handleDelete}
            />
          ))}
        </ul>
        <FruitForm fruitAAjouter={handleAddFruit} />
      </div>
  );

};

export default Formulaire;