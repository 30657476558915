import React from 'react';

import '../styles/pages/_about-us.scss';
import '../styles/components/_navigation-main-menu.scss';
import '../styles/components/_footer.scss'

import imageBannerSrcJpg from '../assets/images/Hawaii-Landscaping-Company-scaled.jpg';
import imageBannerSrcWebp from '../assets/images/Hawaii-Landscaping-Company-scaled.webp';

import NavigationMainMenu from '../components/NavigationMainMenu'
import Footer from '../components/Footer'
import Logo from '../components/Logo'

const AboutUs = () => {
    return (
        <div className='about-us'>
            <NavigationMainMenu/>
            <Logo/>
            <div className='banner-about-us'>
                <span>About us</span>
                <picture>
                    <source type='image/webp' srcSet={imageBannerSrcWebp} />
                    <img src={imageBannerSrcJpg} alt="Hawaii landscape with trees on wind" />
                </picture>
            </div>

            <h1>Oahu Tree Trimming and Removal Experts</h1>
            <p>We pride ourselves on high morals and attention to detail. In order to construct a successful team, there must be values set in place. We put a high value on integrity, doing what is right when you aren't there to watch. We don't cut corners because we want you to have the product you paid for. We also get a lot of our work on a referral basis, which stands as even more of a motivator to get the job done right. Give us a call and we assure you we will meet your expectations.
            </p>

            <h2>Craftmanship</h2>
            <p>We operate on a level of precision and skill. Our contractors doing tree trimming and removal have been completing tree service jobs for many years. We only hire the best tree service contractors around. We take pride in our work and always strive to be the best tree service crew on the island of Oahu.
            </p>

            <Footer/>

        </div>
    );
};

export default AboutUs;