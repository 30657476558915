import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Formulaire from "./pages/Formulaire"
import Error404 from "./pages/Error404"
import Api from "./pages/Api"
import Test from "./pages/Test"
import HomeTrimming from "./pages/HomeTrimming"
import Services from "./pages/Services"
import AboutUs from "./pages/AboutUs"
import Contact from "./pages/Contact"
import Font from "./pages/Font"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/formulaire" element={<Formulaire />}/>
        <Route path="/api" element={<Api />}/>
        <Route path="/test" element={<Test />}/>
        <Route path="/font" element={<Font />}/>
        <Route path="/home-trimming" element={<HomeTrimming />}/>
        <Route path="/services" element={<Services />}/>
        <Route path="/about" element={<AboutUs />}/>
        <Route path="/contact" element={<Contact />}/>
        {/* path="*" fonctionne si jamais l'url ne correspon à rien de déclaré au dessus */}
        <Route path="*" element={<Error404 />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

//Gestion du formulaire
