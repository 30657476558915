import React from 'react';

import '../styles/pages/_font.scss';
import '../styles/components/_footer.scss'

import Navigation from '../components/Navigation'
import Footer from '../components/Footer'

const Font = () => {
    return (
        <div className="page-font">
            <Navigation/>
            <h1>Font</h1>

            <p className='p1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur beatae ratione molestias sunt odit aut error recusandae corporis pariatur. Dolores officiis deserunt, aut necessitatibus nisi maiores voluptate laudantium. Sed accusamus ex quae a rem eos odio eligendi recusandae? Dicta nisi odit ex totam, aspernatur enim voluptatibus corporis et officiis sapiente? Esse blanditiis voluptas dicta, magni animi odio facere iure ratione error quisquam dolores necessitatibus. Nostrum nulla asperiores esse ab autem incidunt aut facilis, eveniet blanditiis at dolore vel fugit quod non culpa nesciunt dolor. Laudantium repellendus illum rem tenetur quo reiciendis et quibusdam sunt veniam iusto explicabo, commodi vitae incidunt.
            </p>
            <p className='p2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur beatae ratione molestias sunt odit aut error recusandae corporis pariatur. Dolores officiis deserunt, aut necessitatibus nisi maiores voluptate laudantium. Sed accusamus ex quae a rem eos odio eligendi recusandae? Dicta nisi odit ex totam, aspernatur enim voluptatibus corporis et officiis sapiente? Esse blanditiis voluptas dicta, magni animi odio facere iure ratione error quisquam dolores necessitatibus. Nostrum nulla asperiores esse ab autem incidunt aut facilis, eveniet blanditiis at dolore vel fugit quod non culpa nesciunt dolor. Laudantium repellendus illum rem tenetur quo reiciendis et quibusdam sunt veniam iusto explicabo, commodi vitae incidunt.
            </p>
            <p className='p3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur beatae ratione molestias sunt odit aut error recusandae corporis pariatur. Dolores officiis deserunt, aut necessitatibus nisi maiores voluptate laudantium. Sed accusamus ex quae a rem eos odio eligendi recusandae? Dicta nisi odit ex totam, aspernatur enim voluptatibus corporis et officiis sapiente? Esse blanditiis voluptas dicta, magni animi odio facere iure ratione error quisquam dolores necessitatibus. Nostrum nulla asperiores esse ab autem incidunt aut facilis, eveniet blanditiis at dolore vel fugit quod non culpa nesciunt dolor. Laudantium repellendus illum rem tenetur quo reiciendis et quibusdam sunt veniam iusto explicabo, commodi vitae incidunt.
            </p>
            <p className='p4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur beatae ratione molestias sunt odit aut error recusandae corporis pariatur. Dolores officiis deserunt, aut necessitatibus nisi maiores voluptate laudantium. Sed accusamus ex quae a rem eos odio eligendi recusandae? Dicta nisi odit ex totam, aspernatur enim voluptatibus corporis et officiis sapiente? Esse blanditiis voluptas dicta, magni animi odio facere iure ratione error quisquam dolores necessitatibus. Nostrum nulla asperiores esse ab autem incidunt aut facilis, eveniet blanditiis at dolore vel fugit quod non culpa nesciunt dolor. Laudantium repellendus illum rem tenetur quo reiciendis et quibusdam sunt veniam iusto explicabo, commodi vitae incidunt.
            </p>
            <p className='p5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur beatae ratione molestias sunt odit aut error recusandae corporis pariatur. Dolores officiis deserunt, aut necessitatibus nisi maiores voluptate laudantium. Sed accusamus ex quae a rem eos odio eligendi recusandae? Dicta nisi odit ex totam, aspernatur enim voluptatibus corporis et officiis sapiente? Esse blanditiis voluptas dicta, magni animi odio facere iure ratione error quisquam dolores necessitatibus. Nostrum nulla asperiores esse ab autem incidunt aut facilis, eveniet blanditiis at dolore vel fugit quod non culpa nesciunt dolor. Laudantium repellendus illum rem tenetur quo reiciendis et quibusdam sunt veniam iusto explicabo, commodi vitae incidunt.
            </p>
            <p className='p6'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur beatae ratione molestias sunt odit aut error recusandae corporis pariatur. Dolores officiis deserunt, aut necessitatibus nisi maiores voluptate laudantium. Sed accusamus ex quae a rem eos odio eligendi recusandae? Dicta nisi odit ex totam, aspernatur enim voluptatibus corporis et officiis sapiente? Esse blanditiis voluptas dicta, magni animi odio facere iure ratione error quisquam dolores necessitatibus. Nostrum nulla asperiores esse ab autem incidunt aut facilis, eveniet blanditiis at dolore vel fugit quod non culpa nesciunt dolor. Laudantium repellendus illum rem tenetur quo reiciendis et quibusdam sunt veniam iusto explicabo, commodi vitae incidunt.
            </p>
            <p className='p7'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur beatae ratione molestias sunt odit aut error recusandae corporis pariatur. Dolores officiis deserunt, aut necessitatibus nisi maiores voluptate laudantium. Sed accusamus ex quae a rem eos odio eligendi recusandae? Dicta nisi odit ex totam, aspernatur enim voluptatibus corporis et officiis sapiente? Esse blanditiis voluptas dicta, magni animi odio facere iure ratione error quisquam dolores necessitatibus. Nostrum nulla asperiores esse ab autem incidunt aut facilis, eveniet blanditiis at dolore vel fugit quod non culpa nesciunt dolor. Laudantium repellendus illum rem tenetur quo reiciendis et quibusdam sunt veniam iusto explicabo, commodi vitae incidunt.
            </p>
            <Footer/>
        </div>
    );
};

export default Font;