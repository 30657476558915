import React, { useState } from 'react';
import Navigation from '../components/Navigation'
import imageSrc from '../assets/images/Hawaii-Landscaping-Company-scaled.jpg';
import imageSrc2 from '../assets/images/Hawaii-Landscaping-Services-scaled.jpg';
import '../styles/pages/_test.scss';
import '../styles/components/_navigation-main-menu.scss';
import '../styles/components/_footer.scss'
import Footer from '../components/Footer'



const Test = () => {
    const [current3DEffect, setCurrent3DEffect] = useState("display2d");
    return (
        <div className='test'>
            <Navigation/>
            <h1>Test</h1>

            {/* <div className="container-first-img-test">
                <img alt="trimming" src={imageSrc} className='first-img-test'/>
            </div> */}
            {/* <img alt="trimming" src="./images/Hawaii-Landscaping-Company-scaled.jpg" /> */}


            <div className="box">
                <div>
                    <img className={`image-cube-1 ${current3DEffect}`} alt="trimming" src={imageSrc2}/>
                </div>
            </div>

            <div className='container-button'>
                <button onClick={()=>(current3DEffect === "display3d") ? setCurrent3DEffect("display2d") : setCurrent3DEffect("display3d")}>Change image effect</button>
            </div>

            <div className="box">
                <div>
                    <img
                     onClick={()=>(current3DEffect === "display3d") ? setCurrent3DEffect("display2d") : setCurrent3DEffect("display3d")}
                    alt="trimming" src={imageSrc}  className={`clicable image-cube-1 ${current3DEffect}`}/>
                </div>
            </div>

            <hr />
            <h2>test media queries</h2>
            <div className='carre'></div>
            <Footer></Footer>

        </div>
    );


};

export default Test;