import { useState } from "react"

export default function FruitForm({fruitAAjouter}){
    //state
  const [nouveauFruit, setNouveauFruit] = useState("");


    //comportement
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("handleSubmit");

    const id = new Date().getTime();
    const nom = nouveauFruit;
    fruitAAjouter({id,nom});
    setNouveauFruit("");
  }

  const handleChange = (event) =>{
    console.log(event.target.value);
    setNouveauFruit(event.target.value);
  };

    //affichage (render)
    return (
        <form action="submit" onSubmit={handleSubmit}>
          <input onChange={handleChange} value={nouveauFruit} type="text" placeholder="ajouter un fruit..."/>
          <button>Ajouter +</button>
        </form>
    );
}