import React from "react";

const CardTelephone = () => {
	return (
		<div className="componant-card-telephone">
			<a href="tel:8087930000">
				<div className="container-card-telephone">
					<div className="card-telephone">
						<span className="icone-telephone"></span>
						<span className="numero-tel">808-793-0000</span>
					</div>
				</div>
			</a>
		</div>
	);
};

export default CardTelephone;
