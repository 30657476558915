import React from 'react';

import srcImageLogo from '../assets/logo/logo.jpg';

import '../styles/components/_card-telephone.scss'

import CardTelephone from '../components/CardTelephone'


const Logo = () => {
    return (
        <div className='row-logo'>
            <div className='container-image-logo'>
                <img className='image-logo' src={srcImageLogo} alt="Hawaii landscape with trees on wind" />
            </div>
            <div className='container-component-card-telephone'>
                <CardTelephone/>
            </div>
        </div>
    );
};

export default Logo;